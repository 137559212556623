body, .App {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  min-height: 100vh; /* Set minimum height to fill the viewport */
}

.home-container {
  max-width: 100%; /* Ensure container does not exceed viewport width */
  overflow-x: hidden; /* Prevent overflow in container */
}
.App-header {
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  padding: 15px;
}
.header-logo {
  margin-right: 30px; /* Space on the left */
}

.nav-container {
  width: 100%; /* Ensure nav takes up the full width */
  background-color: #ffffff; /* Card-like background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Creates the raised card effect */
  padding: 20px;
  border-radius: 8px; /* Optional: Can remove for sharp corners */
  
  border: 1px solid rgba(0, 0, 0, 0.1); /* Border for more definition */
  display: flex;
  
  justify-content: flex-end; /* Distribute nav links evenly across the width */
}

.header-nav {
  display: flex; /* Use flex for nav items */
  gap: 20px; /* Add space between links */
}

.logo {
  width: 160px;  /* Adjust the width to fit your layout */
  height: auto;  /* This ensures the aspect ratio is maintained */
  max-width: 100%;  /* Prevents the image from exceeding the container size */
  display: block;  /* Ensures it's treated as a block-level element */
  margin: 0 20px;  /* Center the logo horizontally */
}


.header-contact {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right */
  margin-right: 20px; /* Adjust as needed */
  font-size: 15px;
}

/* .header-contact i {
  margin-right: 5px; 
}

.email-link {
  color: #007bff; 
  text-decoration: none; 
}

.email-link:hover {
  text-decoration: underline; 
} */

nav a {
  position: relative;  /* Required to position the pseudo-element */
  color: black;        /* Default link color */
  text-decoration: none;  /* Remove default underline */
  padding: 10px 20px;  /* Adjust padding around the links */
  font-size: 18px;     /* Set your desired font size */
  font-weight: 600;
}

/* Add the blue line using a pseudo-element */
nav a::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;  /* Position it slightly below the link text */
  height: 3px;   /* Thickness of the blue line */
  background-color: #0077b5;  /* Blue color */
  transform: scaleX(0);  /* Initially hidden */
  transition: transform 0.3s ease;  /* Smooth transition effect */
}

/* On hover, expand the blue line */
nav a:hover::after {
  transform: scaleX(1);  /* Fully reveal the line on hover */
}


/* Header styling adjustments for mobile */
@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
    padding: 10px 20px;
  }

  .header-logo {
    margin-bottom: 10px; 
    text-align: center; 
  }

  .nav-container {
    justify-content: center; /* Center nav links within container */
    margin-top: 10px;
    padding: 10px 0;
  }

  .header-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  nav a {
    margin: 5px 0; /* Stack links vertically with spacing */
    padding: auto;
    font-size: 16px;
    text-align: center;
  }

  /* Adjust logo size on smaller screens */
  .logo {
    width: 140px;
  }
}

@media (max-width: 450px) {

  .App-header {
    padding: 8px 15px; /* Reduce overall padding */
    align-items: center; /* Ensures centered alignment on smaller screens */
  }

  .header-logo img {
    width: 100px; /* Further reduce logo size for small screens */
  }

  .header-nav {
    padding: 5px 10px;
    text-align: center;
    margin-left: 0; 
  }

  nav a {
    font-size: 13px; /* Smaller font for nav links */
    padding: 10px 12px; 
    text-align: center;
  }

}



/*App Content*/

.App-content {
  flex: 1; /* Allow content to grow and fill the space */
}

/*Hero CSS*/

#hero {
  padding: 70px 20px; /* Increase padding for more space */
  background-color: #fdfdef; /* Light yellow background to grab attention */
  text-align: center;
  color:darkblue; /* Darker color for contrast */
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

#hero h2 {
  font-size: 3em; /* Larger heading size */
  font-weight: bold;
  margin-bottom: 20px;
}

#hero p {
  font-size: 1.5em; /* Larger paragraph size */
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#hero button {
  font-size: 1.2em; /* Increase button font size */
  padding: 15px 40px; /* Larger button */
  background-color: teal; /* Eye-catching orange button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#hero button:hover {
  background-color: #0077b5;  /* Blue color */ /* Darker orange on hover */
}

/*Card CSS*/

#services {
  padding: 40px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  align-items: start;
}

/* Base styles for cards */
.card {
  background-color: #0c0356;
  color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 280px;
  min-height: 90px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Hide content overflow */
  z-index: 1;
}

/* Hover effect for the card */
.card:hover {
  transform: scale(1.05); /* Grows the card */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Adds a stronger shadow */
  background-color: #f9f9f9; /* Lighten background on hover */
  z-index: 10;
}

/* Initially hide service details */
.service-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease, opacity 0.3s ease; /* Smooth transition */
  opacity: 0;
}

/* On hover, reveal the service details */
.card:hover .service-details {
  max-height: 200px; /* Adjust according to content size */
  opacity: 1;
  color: black;
}

/* Base styling for service details */
.service-details ul {
  margin: 10px 0;
  padding-left: 20px;
}

.service-details li {
  margin-bottom: 10px;
}

/* Add a subtle text animation */
.card:hover h3 {
  color:darkblue; 
  transition: color 0.3s ease;
}

/* Card layout adjustments for small screens */
@media (max-width: 768px) {
  .service-cards {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
    gap: 15px; /* Reduce gap for smaller screens */
  }

  .card {
    max-width: 100%;
    padding: 15px;
    font-size: 0.9rem; /* Slightly smaller font */
    min-height: 190px; /* Increase min-height for content balance */
    max-height: 250px; /* Decrease max height to fit small screen */
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
  }

  .card h3 {
    font-size: 1.2rem; /* Reduce header font size slightly */
    margin: 0;
    padding: 0 0 10px; /* Add padding below the header */
    transition: all 0.3s ease; /* Transition for hover effect */
  }

  .service-details {
    max-height: 100px; /* Reduce height of details on smaller screens */
    font-size: 0.9rem;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .card:hover h3 {
    font-size: 1rem; /* Slightly smaller font on hover */
    padding-bottom: 5px; /* Reduce padding for a more compact look */
    color: #333; /* Optional: Change color on hover */
  }

  .card:hover .service-details {
    max-height: 200px; /* Expand details section */
    font-size: 0.85rem; /* Slightly smaller font for compact look */
  }
}

@media (max-width: 480px) {
  .service-cards {
    grid-template-columns: 1fr; /* 1 column for smaller screens */
  }

  
}





/* Collaborators CSS */

#Collaborators {
  text-align: center;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  width: 100%; /* Ensures it spans the entire container width */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  h2 {
    font-size: 1.5em;
  }
}

@media (max-width: 450px) {
  h2 {
    font-size: 1.2em;
    text-align: center;
  }
}

/* Style for logo container */
.logo-container {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

/* Adjust logo size on smaller screens */
@media (max-width: 768px) {
  .logo img {
    max-width: 80px;
    max-height: 40px;
  }
}

@media (max-width: 450px) {
  .logo img {
    max-width: 60px;
    max-height: 30px;
  }
}



/*Footer CSS*/
footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  text-align: center; /* Center text for fallback */
}

.footer-content {
  display: flex;
  flex-direction: row; /* Keep horizontal alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center the content as a whole */
  position: relative; /* Allow absolute positioning of social icons */
  padding: 0 20px; /* Add padding to the left and right */
}

.footer-content p {
  margin: 0;
  font-size: 14px;
  flex: 1; /* Allow the copyright text to take up remaining space */
}

/* Social icons styling */
ul.social-icons {
  list-style: none;
  padding: 0;
  margin: 10px; /* Remove margin for better alignment */
  display: flex;
  align-items: center; /* Center icons vertically */
  position: absolute; /* Position it relative to footer-content */
  left: 20px; /* Move it further left */
}

ul.social-icons li {
  margin-right: 20px; 
}

/* LinkedIn icon link styles */
ul.social-icons a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

ul.social-icons a:hover {
  color: #0077b5; /* LinkedIn Blue on hover */
}

.fa-linkedin {
  font-size: 2em; /* Adjust icon size */
}



/* Footer responsiveness */
@media (max-width: 768px) {
  footer {
    padding: 20px;
    text-align: center;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  ul.social-icons {
    justify-content: center;
    padding-bottom: 10px;
    position: static; /* Positions icons normally on mobile */
  }

  ul.social-icons li {
    margin-right: 15px;
  }

  .footer-content p {
    margin-top: 10px;
    font-size: 12px;
  }
}

.contact-section {
  max-width: 800px;
  margin: 20px auto; /* Center the section with margin on top and bottom */
  padding: 20px;
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.contact-section h2 {
  text-align: center; /* Center the heading */
  color: #333; /* Dark color for text */
}

.contact-section p {
  text-align: center; /* Center introductory text */
  color: #555; /* Lighter color for intro text */
}

.contact-form {
  display: flex;
  flex-direction: column; /* Stack inputs vertically */
  gap: 15px; /* Space between form elements */
}

.form-group {
  display: flex;
  flex-direction: column; /* Stack label and input */
}

.form-group label {
  margin-bottom: 5px; /* Space between label and input */
  font-weight: bold; /* Make labels bold */
}

.form-group input,
.form-group textarea {
  padding: 10px; /* Padding for inputs */
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Increase font size */
}

.form-group textarea {
  resize: vertical; /* Allow vertical resize only */
  min-height: 100px; /* Minimum height for textarea */
}

button {
  padding: 10px 15px; /* Padding for the button */
  background-color: #0077b5; /* LinkedIn blue color */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  font-size: 16px; /* Increase font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Transition for hover effect */
}

button:hover {
  background-color: #005582; /* Darker blue on hover */
}

.contact-info {
  margin-top: 20px; /* Space above contact info */
  text-align: center; /* Center contact info */
}

.contact-info h3 {
  margin-bottom: 10px; /* Space below heading */
}